import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import PropTypes from 'prop-types';

function DroppDown({ direction, ...args }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const { sorthandler, sortBy, ...other } = args;

    return (
        <div className="d-flex justify-content-end">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                <DropdownToggle caret size="sm">Sort</DropdownToggle>
                <DropdownMenu end {...other}>
                    {/* <DropdownItem header>Sort posts by:</DropdownItem> */}
                    <DropdownItem className={sortBy === "createdAt" ? "active" : ""} onClick={() => args.sorthandler("createdAt")}>New</DropdownItem>
                    <DropdownItem className={sortBy === "likesCount" ? "active" : ""} onClick={() => args.sorthandler("likesCount")}>Most Liked</DropdownItem>
                    <DropdownItem disabled>Most Talked About</DropdownItem>
                    {/* <DropdownItem divider /> */}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

DroppDown.propTypes = {
    direction: PropTypes.string,
};

export default DroppDown;