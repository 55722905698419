import React, { useEffect, useRef } from 'react';

// uses js async code 
// displays multiple ads on a single page
const Adsterra2 = ({slot, index}) => {
  const ref = useRef();

  let idd = `atContainer-${slot}${index}`;

  useEffect(() => {
    if (!ref.current.firstChild && slot) {
      const atAsyncOptions = {
        key: slot,
        format: 'js',
        async: true,
        container: idd,
        params: {},
      };

      const conf = document.createElement('script');
      conf.innerHTML = `
        if (typeof atAsyncOptions !== 'object') var atAsyncOptions = [];
        atAsyncOptions.push(${JSON.stringify(atAsyncOptions, null, 2)});
      `;
      conf.type = 'text/javascript';

      const script = document.createElement('script');
      script.async = true;
      script.src = 'http' + (window.location.protocol === 'https:' ? 's' : '') + '://www.topcreativeformat.com/'+slot+'/invoke.js';
      script.type = 'text/javascript';

      if (ref.current) {
        ref.current.append(conf);
        ref.current.append(script);
      }
    }
  }, []);

  return (
    <>
      {/* <p style={{ fontSize: "10px" }}>Ads help support this website.</p> */}
      <div { ...{ ref } } />
      <div id= { idd } />
    </>
  );
};

export default Adsterra2;