import React from "react";
import Navibar from "./Components/Navibar"
import Posts from "./Components/Posts"
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import CreatePost from "./Components/CreatePost"
import NoPage from "./Components/NoPage"
import "./App.css"

import firebase from 'firebase/compat/app';
import {useAuthState} from 'react-firebase-hooks/auth';

export default function App () {

  const auth = firebase.auth();
  // const { uid, photoURL, email } = auth.currentUser;

  const [user] = useAuthState(auth);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider).catch((error) => console.log(error));
  }

  const signOut = () => {
    auth.signOut().catch((error) => console.log(error));
  }

  return (
    <BrowserRouter>
      <Navibar user={user} signinwithgoogle={signInWithGoogle} signout={signOut}/>
      <Routes>
        <Route index path="/" element={<Posts user={user} />}/>
        <Route path="/mood/:mood" element={<Posts user={user} />}/>
        <Route path="/newpost" element={<CreatePost user={user}/>}/>
        <Route path="*" element={<NoPage/>}/>
      </Routes>
    </BrowserRouter>      
  )
}