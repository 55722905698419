import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverHeader,
} from 'reactstrap';

export default function Navibar(args) {

    const [navbarIsOpen, setNavbarIsOpen] = useState(false);
    const toggleNavbar = () => setNavbarIsOpen(!navbarIsOpen);

    const { signout, signinwithgoogle, ...other } = args;

    const mystyle = {
        fontFamily: "Snell Roundhand, cursive",
        fontSize: "25px"
    }

    const ref = React.useRef(null);

    const [isPopOverOpen, setIsPopOverOpen] = React.useState(false);

    React.useEffect(() => {

        if (window.innerWidth < 576) {
            setIsPopOverOpen(true);
        }

        setTimeout(() => { setIsPopOverOpen(false) }, 3000);

    }, [])

    return (
        <Navbar {...other} className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container color='dark' dark={true}>
            <NavbarBrand href="/" style={mystyle} >moodie5</NavbarBrand>
            {/* <NavbarToggler  onClick={toggle} /> -- using the button with class navbar-toggler below instead of the NavbarToggler element */}
            <button aria-label="Toggle navigation" type="button" className="navbar-toggler" onClick={toggleNavbar}><span ref={ref} className="navbar-toggler-icon"></span></button>
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={navbarIsOpen} navbar>
                <Nav className="d-flex justify-content-end" navbar>
                    {
                        args.user &&
                        <NavItem>
                            <NavLink className="text-light">{args.user.email.match(/(.*)@gmail.com/)[1]}</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink className="text-light" href="/newpost">Create Post</NavLink>
                    </NavItem>
                    {
                        args.user ?
                            <NavItem>
                                <NavLink className="text-light" type='button' onClick={() => signout()}>Sign Out</NavLink>
                            </NavItem>
                            :
                            <NavItem>
                                <NavLink className="text-light" type='button' onClick={() => signinwithgoogle()}>Sign In</NavLink>
                            </NavItem>
                    }
                </Nav>
            </Collapse>
            <Popover
                placement="bottom"
                target={ref}
                toggle={function noRefCheck() { }}
                isOpen={isPopOverOpen}
            >
                <PopoverHeader>
                    Click Me!
                </PopoverHeader>
            </Popover>
        </Navbar>
    );

}
