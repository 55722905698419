import React from "react"

import {
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';

export default function Navi(props) {

    const moods = [
        { 
            "id" : 1,
            mood: "upbeat",
            title: "something funny"
        },
        { 
            "id" : 2,
            mood: "woe",
            title: "something sad"
        },
        { 
            "id" : 3,
            mood: "excite",
            title: "something that causes anxiety, fear, stress AKA excitement!"
        },
        { 
            "id" : 4,
            mood: "lust",
            title: "something 😈"
        },
        { 
            "id" : 5,
            mood: "meh",
            title: "something whatever.."
        }
    ]

    return (
        <Nav tabs>
            {
                moods.map(mood => {
                    return (
                    <NavItem key={mood.id}>
                        <NavLink
                            className={ props.mood === mood.mood ? "active" : ""} 
                            href={`/mood/${mood.mood}`}
                            title={mood.title}
                        >
                            {mood.mood}
                        </NavLink>
                    </NavItem>
                    )
                })
            }
        </Nav>
    )
}