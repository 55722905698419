import React from "react"
import Post from "./Post"
import Over18Modal from "./Over18Modal"

// firebase SDK
import firebase from 'firebase/compat/app';

// firebase hooks
// import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

const LOCAL_STORAGE_KEY = "showmodal";

export default function MoodSorter(props) {

    const [modalData, setModalData] = React.useState({});
    const [toggle, setToggle] = React.useState(true)
    const [list, setList] = React.useState([]);
    const [lastVisible, setLastVisible] = React.useState();
    const [lastPostID, setLastPostID] = React.useState("")

    React.useEffect(() => {
        console.log("initial fetch");
        let query = null;
        if (props.mood) {
            query = firebase.firestore().collection('posts')
                .where("mood", "==", props.mood)
                .orderBy(props.sortBy, 'desc')
                .limit(20);
        }
        else {
            query = firebase.firestore().collection('posts')
                .orderBy(props.sortBy, 'desc')
                .limit(20);
        }

        query.get().then((documentSnapshots) => {
            let items = [];
            documentSnapshots.forEach(function (doc) {
                items.push({ id: doc.id, ...doc.data() });
            });
            // console.log('first item ', items[0])

            if (props.sortBy === "createdAt")
                shuffleArray(items);

            setList(items);
            // console.log(list[list.length-1]);
        })

        query.get().then((documentSnapshots) => {
            // Get the last visible document
            let lastVisiblee = documentSnapshots.docs[documentSnapshots.docs.length - 1];
            // console.log("last", lastVisiblee.data());
            setLastVisible(lastVisiblee);
        })
    // eslint-disable-next-line
    }, [props.sortBy])

    React.useEffect(() => {
        if (lastVisible) {
            console.log("proceeding fetches");
            let query = null;

            if (props.mood) {
                query = firebase.firestore().collection('posts')
                    .where("mood", "==", props.mood)
                    .orderBy(props.sortBy, 'desc')
                    .startAfter(lastVisible)
                    .limit(10);
            } else {
                query = firebase.firestore().collection('posts')
                    .orderBy(props.sortBy, 'desc')
                    .startAfter(lastVisible)
                    .limit(10);
            }

            setLastPostID(list[list.length-1].id);

            query.get().then((documentSnapshots) => {
                let items = [];
                documentSnapshots.forEach(function (doc) {
                    items.push({ id: doc.id, ...doc.data() });
                });
                // console.log('first item ', items[0])

                if (props.sortBy === "createdAt")
                    shuffleArray(items);

                setList(list => [...list, ...items]);
                // console.log(list[list.length-1]);
            })

            query.get().then((documentSnapshots) => {
                // Get the last visible document
                let lastVisiblee = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                // console.log("last", lastVisiblee);
                setLastVisible(lastVisiblee);
            })
        }
    // eslint-disable-next-line
    }, [toggle]);

    React.useEffect(() => {
        const handleScroll = () => {
            if (
                document.body.scrollHeight - 300 <
                window.scrollY + window.innerHeight && lastVisible
            ) {
                setToggle(!toggle);
            }
        };

        function debounce(func, delay) {
            let timeoutId;
            return function (...args) {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
        }

        window.addEventListener("scroll", debounce(handleScroll, 500));
        return () => {
            window.removeEventListener("scroll", debounce(handleScroll, 500));
        };
    });

    React.useEffect(() => {
        setTimeout(() => {
            const element = document.getElementById(lastPostID);
            if (element) {
                console.log("scrolling to " + element.id);
                element.scrollIntoView({behavior: "instant"});
            }
        }, 500)
    // eslint-disable-next-line
    }, [list]);

    React.useEffect(() => {
        const retrievedModalData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        if (retrievedModalData) {
            setModalData(retrievedModalData);
        }
    }, []);

    React.useEffect(() => {
        if (Object.keys(modalData).length !== 0) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(modalData));
        }
    }, [modalData]);

    return (
        <>
            <WhatToDisplay />
        </>
    )

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    function modalDataHandler(state) {
        // console.log("modalDataHandler " + state.isOver18)
        setModalData(state)
    }

    function deleteModalHandler(post) {
        // log data to be delete for now
        // console.log(post);

        const firestore = firebase.firestore();

        if (post.photoURL !== "") {
            console.log("file exists");
            // delete the image if it exists

            let storageRef = firebase.storage().ref();
            let imagesRef = storageRef.child("/" + post.photoURL.match(/(.*\/o\/)(.*)(\?.*)/)[2].replaceAll('%20', " "));

            // Delete the file
            imagesRef.delete().then(() => {
                // File deleted successfully
                console.log("image deletion")

            }).then(() => {
                // deletes likes subcollection
                firestore.collection("posts").doc(post.id).collection("likes").get()
                    .then(res => {
                        res.forEach(element => {
                            element.ref.delete();
                            console.log("like subcollection deletion");
                        });
                    });
            }).then(() => {
                // deletes post document
                firestore.collection("posts").doc(post.id).delete().then(() => {
                    console.log("Post deletion")
                });
            }).catch((error) => {
                console.log(error);
            });
        } else {
            console.log("text post");
            // deletes likes subcollection
            firestore.collection("posts").doc(post.id).collection("likes").get()
                .then(res => {
                    res.forEach(element => {
                        element.ref.delete();
                        console.log("Like sub-collection deleted!");
                    });
                }).then(() => {
                    firestore.collection("posts").doc(post.id).delete().then(() => {
                        console.log("Post deleted!");
                    });
                }).catch((error) => {
                    console.log(error);
                });
        }
        setList(prevList => prevList.filter(x => x.id !== post.id));
    }

    function WhatToDisplay() {
        // this is lust mood logic
        if (props.mood === "lust") {
            // if there is no modal data -> show modal data
            if (Object.keys(modalData).length === 0) {
                return (
                    <Over18Modal modalDataHandler={modalDataHandler} />
                )
            }
            // if over 18 -> show lust mood
            if (modalData.isOver18) {
                return (<>{list && list.map((post, index) => <Post key={post.id} index={index} mood={props.mood} user={props.user} content={post} deleteModalHandler={deleteModalHandler} />)}</>)
            }
            // else simply show warning that adult content is not allowed be viewed
            else {
                return (
                    <div className="row mb-4 justify-content-center">
                        <div className="col-md-8">
                            <h4>This mood shows adult content. You must be over 18 to view it.</h4>
                        </div>
                    </div>
                )
            }
        }
        // any other mood just display certain mood content
        else if (props.mood) {
            return (<>{list && list.map((post, index) => <Post key={post.id} index={index} mood={props.mood} user={props.user} content={post} modalData={modalData} deleteModalHandler={deleteModalHandler} />)}</>)
        }
        // now on to the homepage
        else {
            // console.log("homepage")
            return (<>{list && list.map((post, index) => <Post key={post.id} index={index} mood={props.mood} user={props.user} content={post} modalData={modalData} deleteModalHandler={deleteModalHandler} />)}</>)
        }
    }
}