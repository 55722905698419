import React from 'react'
import { useParams } from 'react-router-dom'

import Navi from "./Navi"
import MoodSorter from './MoodSorter';

import DroppDown from "./DroppDown";

export default function Posts(props) {

    const [sortBy, setSortBy] = React.useState("createdAt");

    const { mood } = useParams()

    React.useEffect(() => {
    }, [mood]);

    const sorthandler = (e) => {
        // console.log(e);
        setSortBy(e);
    }

    return (
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <Navi mood={mood} />
                </div>
            </div>
            <div className='row mt-0 mb-4 justify-content-center'>
                <div className='col-md-8'>
                    <DroppDown sorthandler={sorthandler} sortBy={sortBy} />
                </div>
            </div>
            <MoodSorter mood={mood} {...props} sortBy={sortBy} />
        </div>
    );
}
