import React from 'react'

// firebase SDK
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// firebase hooks
// import {useAuthState} from 'react-firebase-hooks/auth';

export default function CreatePost(props) {

    const moods = {
        1: "upbeat",
        2: "woe",
        3: "excite",
        4: "lust",
        5: "meh"
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-lg-8'>
                    {props.user ? <NewPost /> : <h4>Please Sign In above.</h4>}
                </div>
            </div>
        </div>
    )

    function NewPost() {
        const firestore = firebase.firestore();
        const [formData, setFormData] = React.useState({ title: '', body: '', postPhotoURL: '', mood: '' });

        const [showMessage, setShowMessage] = React.useState(false);
        const [message, setMessage] = React.useState("")

        function changeHandler(e) {
            const { name, value } = e.target;

            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }))
        }

        const submitHandler = async (e) => {
            e.preventDefault();

            if (formData.title === '') {
                setMessage("Please enter a Title.")
                setShowMessage(true);
                return;
            }

            if (!["1", "2", "3", "4", "5"].includes(formData.mood)) {
                setMessage("Please set the Mood.")
                setShowMessage(true);
                return;
            }

            if (e.target[2].files[0] && e.target[2].files[0].size > 10000000) {
                setMessage("Files larger than 10 MB are not allowed yet.")
                setShowMessage(true);
                return;
            }

            const postsRef = firestore.collection("posts");
            const { uid, photoURL, email } = props.user;

            // upload the file to firebase storage
            if (e.target[2].files[0]) {
                console.log(e.target[2].files[0].name)

                let file = e.target[2].files[0];

                var storageRef = firebase.storage().ref();
                var imagesRef = storageRef.child(file.name);

                let uploadTask = imagesRef.put(file);

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        if (progress < 100){
                            setMessage("Uploading file...")
                            setShowMessage(true);
                        }    
                        else if (progress === 100){
                            console.log("upload complete")
                        }
                    },
                    (error) => {
                        console.log(error);
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            // post data to firestore db along with the downloadURL
                            postsRef.add({
                                title: formData.title,
                                body: formData.body.replaceAll("\n", "¶"),
                                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                                uid,
                                userPhotoURL: photoURL,
                                photoURL: downloadURL,
                                email,
                                likesCount: 0,
                                mood: moods[formData.mood] || "meh"
                            }).then((value) => {
                                setMessage("Post submitted successfully along with the file!")
                                setShowMessage(true);
                            })
                            setFormData({ title: '', body: '', postPhotoURL: '', mood: '' })
                        });
                    }
                );
            }
            else {
                // post data to firestore db
                postsRef.add({
                    title: formData.title,
                    body: formData.body.replaceAll("\n", "¶"),
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    uid,
                    userPhotoURL: photoURL,
                    photoURL: '',
                    email,
                    likesCount: 0,
                    mood: moods[formData.mood] || "meh"
                }).then((value) => {
                    setMessage("Post submitted successfully!")
                    setShowMessage(true);
                })
                setFormData({ title: '', body: '', postPhotoURL: '', mood: '' })
            }
        }

        return (
            <>
                <form onSubmit={submitHandler}>
                    <div className="mb-3">
                        <label htmlFor="post-title" className="form-label">Title</label>
                        <input className="form-control" id="post-title" name="title" onChange={changeHandler} value={formData.title} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="post-body" className="form-label">Text</label>
                        <textarea className="form-control" id="post-body" name="body" rows="5" onChange={changeHandler} value={formData.body}></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="postPhotoURL" className="form-label">Upload a video, picture, meme, comic, or a GIF!</label>
                        <input className="form-control" type="file" id="postPhotoURL" name="postPhotoURL" onChange={changeHandler} value={formData.postPhotoURL} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="post-mood" className="form-label">Mood</label>
                        <select className="form-select" id="post-mood" name="mood" onChange={changeHandler} value={formData.mood} >
                            <option defaultValue>Set the Mood</option>
                            <option value="1">Upbeat</option>
                            <option value="2">Woe</option>
                            <option value="3">Excite</option>
                            <option value="4">Lust</option>
                            <option value="5">Meh</option>
                        </select>
                    </div>
                    <button type="submit" className="btn btn-primary mt-3">Submit</button>
                </form>
                {showMessage && <div className="alert alert-primary mt-3">{message}</div>}
            </>
        )
    }
}