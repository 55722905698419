import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function DeleteModal(props) {
    return (
        <div>
            <Modal isOpen={props.modalOpen}>
                <ModalHeader>Warning</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete the post? 
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => {props.deleteModalHandler(props.content); props.toggle();}}>
                        Yes
                    </Button>{' '}
                    <Button color="secondary" onClick={() => props.toggle()}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}