// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyD6azgLdZAw47ineo6n6mcIIcTXvyUx5fQ",
    authDomain: "moodit-52bae.firebaseapp.com",
    projectId: "moodit-52bae",
    storageBucket: "moodit-52bae.appspot.com",
    messagingSenderId: "990174351810",
    appId: "1:990174351810:web:d699b95a81ba9943af465b",
    measurementId: "G-DVE235Z2P3"
  };