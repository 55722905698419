import React from "react"
import firebase from 'firebase/compat/app';


export default function Likes(props) {
    const firestore = firebase.firestore();

    const [classString, setClassString] = React.useState("btn btn-outline-primary btn-sm rounded-pill")

    function likeHandler() {
        if (!props.user)
            return;

        let likeDoc = props.likes.find(like => like.uid === props.user.uid);

        if (likeDoc) {
            // has uid -> remove -> set inactive
            firestore.collection("posts").doc(props.content.id).collection("likes").doc(likeDoc.id).delete().then(() => {
                // console.log("Document successfully deleted!");
            }).finally(() => {
                // console.log("finally ran");
                // console.log(Object.keys(props.likes).length)
                firestore.collection("posts").doc(props.content.id).update({
                    likesCount: Object.keys(props.likes).length - 1
                }).then(() => console.log(`Likes set to ${Object.keys(props.likes).length - 1}`));
                setClassString("btn btn-outline-primary btn-sm rounded-pill");
            });
        }
        else {
            // no uid -> add -> set active
            firestore.collection("posts").doc(props.content.id).collection("likes").add({
                uid: props.user.uid
            }).then(() => {
                // console.log("Document successfully added!");
            }).finally(() => {
                // console.log("finally ran");
                firestore.collection("posts").doc(props.content.id).update({
                    likesCount: Object.keys(props.likes).length + 1
                }).then(() => console.log(`Likes set to ${Object.keys(props.likes).length + 1}`));
                setClassString("btn btn-outline-primary btn-sm rounded-pill active");
            });
        }
    }

    return (
        <button type="button" onClick={likeHandler} className={props.classstring || classString} title="Like">
            ❤️ {props.likes && <span className="badge text-bg-danger">{Object.keys(props.likes).length}</span>}
        </button>
    )
}